<template>
  <v-card
    elevation="0"
    v-if="data"
    :img="data.wxCode ? WeathersWxImg[data.wxCode] : null"
    color="#abc0de"
    style="position: relative"
  >
    <v-card-text
      class="fill-height pa-10"
      style="
        background-image: linear-gradient(
          0deg,
          rgb(0 0 0 / 50%),
          transparent 100px
        );
      "
    >
      <v-card
        class="d-flex flex-column"
        color="transparent"
        flat
        tile
        height="100%"
      >
        <v-card class="mb-auto white--text" color="transparent" flat tile>
          <div v-if="data.wxChineseDescription">
            {{ data.wxChineseDescription }}
          </div>
        </v-card>
        <v-card
          v-if="data.temperature"
          class="mb-auto white--text"
          color="transparent"
          flat
          tile
        >
          <div class="text-h3">{{ parseInt(data.temperature) }}°</div>
          <div>
            <span class="mr-5"
              >最高
              <span v-if="data.temperatureMax">
                {{ parseInt(data.temperatureMax) }}°
              </span>
              <span v-else class="mr-5 error--text font-weight-bold">
                維護中</span
              >
            </span>
            <span
              >最低
              <span v-if="data.temperatureMin">
                {{ parseInt(data.temperatureMin) }}°
              </span>
              <span v-else class="error--text font-weight-bold"> 維護中</span>
            </span>
          </div>
        </v-card>
        <v-card class="white--text" color="transparent" flat tile>
          <v-row>
            <v-col v-if="data.humidity" cols="6" lg="3">
              <div>濕度</div>
              <div>
                <span class="text-h5">
                  {{ (data.humidity * 100).toFixed() }}%
                </span>
              </div>
            </v-col>
            <v-col v-if="data.windSpeed" cols="6" lg="3">
              <div>風速</div>
              <div>
                <span class="text-h5">{{ data.windSpeed }}</span>
                <span class="text-h6">級</span>
              </div>
            </v-col>
            <v-col v-if="data.aqi" cols="12" lg="3">
              <div>空氣品質AQI</div>
              <div>
                <AQIBar v-model="data.aqi" />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-card-text>
    <NoWeatherBackground v-if="noWeatherData" />
  </v-card>
</template>

<script>
import Weathers from '@/api/ems/Weathers'
import AQIBar from '@/components/AQIBar'
import NoWeatherBackground from '@/components/NoWeatherBackground'

export default {
  name: undefined,
  mixins: [],
  components: {
    AQIBar,
    NoWeatherBackground
  },
  props: {
    cityId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      WeathersWxImg: {
        1: require('@/assets/image/weather/1/cloud-blue-sky_C.jpg'),
        2: require('@/assets/image/weather/2.3.4/cloud-2294671_C.jpg'),
        3: require('@/assets/image/weather/2.3.4/cloud-2294671_C.jpg'),
        4: require('@/assets/image/weather/2.3.4/cloud-2294671_C.jpg'),
        5: require('@/assets/image/weather/5.6.7/black-rain-abstract-dark-power_C.jpg'),
        6: require('@/assets/image/weather/5.6.7/black-rain-abstract-dark-power_C.jpg'),
        7: require('@/assets/image/weather/5.6.7/black-rain-abstract-dark-power_C.jpg'),
        8: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        9: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        10: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        11: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        12: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        13: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        14: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        15: require('@/assets/image/weather/15-34/thunderstorm-6313125_C.jpg'),
        16: require('@/assets/image/weather/15-34/thunderstorm-6313125_C.jpg'),
        17: require('@/assets/image/weather/15-34/thunderstorm-6313125_C.jpg'),
        18: require('@/assets/image/weather/15-34/thunderstorm-6313125_C.jpg'),
        19: require('@/assets/image/weather/19.21.30/dark-stormy-clouds_C.jpg'),
        20: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        21: require('@/assets/image/weather/19.21.30/dark-stormy-clouds_C.jpg'),
        22: require('@/assets/image/weather/15-34/thunderstorm-6313125_C.jpg'),
        23: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        24: require('@/assets/image/weather/24.25/fog-4735793_C.jpg'),
        25: require('@/assets/image/weather/24.25/fog-4735793_C.jpg'),
        26: require('@/assets/image/weather/26-28/frankfurt-4945405_C.jpg'),
        27: require('@/assets/image/weather/26-28/frankfurt-4945405_C.jpg'),
        28: require('@/assets/image/weather/26-28/frankfurt-4945405_C.jpg'),
        29: require('@/assets/image/weather/8-29/clouds-4793523_C.jpg'),
        30: require('@/assets/image/weather/19.21.30/dark-stormy-clouds_C.jpg'),
        31: require('@/assets/image/weather/31-39/clouds-fog-rain_C.jpg'),
        32: require('@/assets/image/weather/31-39/clouds-fog-rain_C.jpg'),
        33: require('@/assets/image/weather/31-39/clouds-fog-rain_C.jpg'),
        34: require('@/assets/image/weather/15-34/thunderstorm-6313125_C.jpg'),
        35: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_C.jpg'),
        36: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_C.jpg'),
        37: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_C.jpg'),
        38: require('@/assets/image/weather/31-39/clouds-fog-rain_C.jpg'),
        39: require('@/assets/image/weather/31-39/clouds-fog-rain_C.jpg'),
        40: require('@/assets/image/weather/sunny.jpg'),
        41: require('@/assets/image/weather/35-41/thunderstorm-fog-rain_C.jpg'),
        42: require('@/assets/image/weather/42/snowfall-201496_C.jpg')
      },
      data: {}
    }
  },
  computed: {
    noWeatherData() {
      return !Object.values(this.data).filter((value) => value).length
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {
    this.getWeather()
  },
  destroyed() {},
  methods: {
    getWeather() {
      Weathers.city(this.cityId).then((response) => {
        if (!response.data) {
          return
        }
        this.data = (({
          wxCode,
          wxChineseDescription,
          temperature,
          temperatureMax,
          temperatureMin,
          humidity,
          windSpeed,
          aqi
        }) => ({
          wxCode,
          wxChineseDescription,
          temperature,
          temperatureMax,
          temperatureMin,
          humidity,
          windSpeed,
          aqi
        }))(response.data)
        Object.keys(response.data).forEach((key) => {
          if (this.data[key] === '-99' || this.data[key] === '') {
            this.data[key] = null
          }
        })
      })
    }
  }
}
</script>

<style></style>
